import config from './config';

export const authorize = async (loginUrlNonce: string) => {
  const authUrl = `${await config.AUTH_BASE_URL()}/login?&redirect_url=${encodeURIComponent(
    config.redirectUri(),
  )}&nonce=${loginUrlNonce}`;

  window.location.replace(authUrl);
};

export const signOff = async () => {
  const url = `${await config.SIGNOUT_BASE_URL()}/as/signoff`;
  const signOffUrl = `${url}?`;
  window.location.assign(signOffUrl);
};

export const parseHash = (): any => {
  if (window.location.hash === '') return null;
  return window.location.hash
    .replace('#', '')
    .split('&')
    .reduce((prev, item) => {
      return Object.assign({ [item.split('=')[0]]: decodeURIComponent(item.split('=')[1]) }, prev);
    }, {});
};
