import { Spinner } from '@gbg/gbgcomponentlibrary_react';

interface CustomSpinnerProps {
  message: string;
}

const CustomSpinner: React.FC<CustomSpinnerProps> = ({ message }) => {
  return (
    <div className="spinner-container">
      <div className="custom-spinner">
        <Spinner data-testid="spinner" />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default CustomSpinner;
