import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PiiLoggingLevel, Step } from '../../../model/Step';
import { useSteps } from '../../../hooks/useSteps';
import StepForm from './StepForm';
import { Identity } from '../../../model/Identity';
import { isAuthorised } from '../../../services/Authorisation';
import { toUpdate } from '../../../model/RequiredClaims';
import { Dataset } from '../../../model/Dataset';
import { useDatasets } from '../../../hooks/useDatasets';
import { toast, ToastPosition, ToastType, Breadcrumb, ErrorText } from '@gbg/gbgcomponentlibrary_react';
import { Error } from '../../../model/Error';

type AddStepParams = {
  id: string;
  version: string;
};

const newStep: Step = {
  number: -9999,
  name: '',
  piiLoggingLevel: PiiLoggingLevel.OFF,
  httpMethod: '',
  urls: {
    mock: '',
    test: '',
    live: '',
  },
  auditable: false,
};

const defaultDataset = {
  id: null,
  version: null,
  description: 'Loading ...',
  country: '',
  provider: '',
  status: 'live',
  pci_handler: false,
};

const AddStep = ({ identity }: { identity: Identity }) => {
  const { id, version } = useParams<AddStepParams>();
  const [step, setStep] = useState<Step>(newStep);
  const { upsertStep, getSteps } = useSteps();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { getDataset } = useDatasets();
  const [dataset, setDataset] = useState<Dataset>(defaultDataset);

  const isAuthorisedToUpdate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toUpdate);
    return authorised;
  }, [identity]);

  function parseResponse<T>(response: T | Error[], apiName: string): T | null {
    if (Array.isArray(response) && response.length > 0 && 'action' in response[0]) {
      setError(
        `Error calling ${apiName}: ${response.map((error: Error) => `${error.problem}: ${error.action}`).join(', ')}`,
      );
      return null;
    }
    return response as T;
  }

  useEffect(() => {
    getDataset(Number(id), Number(version)).then(response => {
      var result = parseResponse<Dataset>(response, 'Get Dataset');
      if (result) {
        setDataset(result);
      }
    });
  }, [getDataset, id, version]);

  const persistStep = useCallback(
    async (datasetId: number, datasetVersion: number, stepData: Step) => {
      const stepNumber = await getSteps(datasetId, datasetVersion).then(response => {
        if (response.length <= 0) return 0;
        var result = parseResponse<Step[]>(response, 'Get Steps');
        if (!result) {
          return -9999;
        }

        return result.sort((a, b) => b.number - a.number)[0].number + 1;
      });

      if (stepNumber === -9999) return;
      stepData.number = stepNumber;

      const errors = await upsertStep(datasetId, datasetVersion, stepData);
      if (!errors) {
        toast({
          title: '',
          content: `Step ${stepData.name} has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
        navigate(`/datasets/${datasetId}/versions/${datasetVersion}/steps/${stepData.number}`);
      }
      return errors;
    },
    [upsertStep, navigate, getSteps],
  );

  return id && version ? (
    <>
      <Breadcrumb
        crumbs={[
          {
            title: 'Datasets',
            link: 'datasets',
          },
          {
            title: `${dataset.description}`,
            link: `datasets/${id}/versions/${version}`,
          },
          {
            title: 'New Step',
            link: null,
          },
        ]}
      ></Breadcrumb>
      <div>
        <div>
          <h1 data-testid="add-step-header">New Step</h1>
        </div>
      </div>
      <StepForm
        datasetId={id}
        datasetVersion={version}
        step={step}
        setStep={setStep}
        persist={persistStep}
        isAuthorised={isAuthorisedToUpdate && dataset.status.toLowerCase() === 'draft'}
        pci_handler={dataset.pci_handler}
      />
      <ErrorText data-testid="retrieve-steps-error-text">{error}</ErrorText>
    </>
  ) : (
    <></>
  );
};

export default AddStep;
