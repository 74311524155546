import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Identity } from '../model/Identity';

const initialState: Identity = {
  isAuthenticated: false,
  accessToken: '',
  idToken: '',
  licensedProducts: {},
  licensedFunctions: [],
  email: '',
  givenName: '',
  familyName: '',
  companyName: '',
  username: '',
};

const setIdentityReducer: CaseReducer<Identity, PayloadAction<Identity>> = (
  state: Identity,
  action: PayloadAction<Identity>,
) => action.payload;

export const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    setIdentity: setIdentityReducer,
  },
});

export const { setIdentity } = identitySlice.actions;
export const selectIdentity = (state: RootState) => state.identity;
export default identitySlice.reducer;
