import { useCallback, useEffect, useMemo, useState } from 'react';
import { Identity } from '../../../model/Identity';
import { PiiLoggingLevel, Step } from '../../../model/Step';
import StepForm from './StepForm';
import TemplateForm from './TemplateForm';
import { useSteps } from '../../../hooks/useSteps';
import { useNavigate, useParams } from 'react-router-dom';
import { isAuthorised } from '../../../services/Authorisation';
import { toUpdate, toUpdateLoggingPiiLevel } from '../../../model/RequiredClaims';
import { useTemplates } from '../../../hooks/useTemplates';
import { OutputTemplate, Template } from '../../../model/Template';
import { useDatasets } from '../../../hooks/useDatasets';
import { Dataset } from '../../../model/Dataset';
import { OutputTemplatesTable } from '../../output-templates-table/OutputTemplatesTable';
import TabBar from '../../../components/tabs/TabBar';
import {
  toast,
  ToastPosition,
  ToastType,
  Breadcrumb,
  TabPanel,
  Button,
  ErrorText,
} from '@gbg/gbgcomponentlibrary_react';

function isStep(arg: any): arg is Step {
  return arg.name != null;
}

function isTemplate(arg: any): arg is Template {
  return arg.template != null;
}

function isOutputTemplates(arg: any): arg is OutputTemplate[] {
  return arg[0].status != null && arg[0].template != null;
}

function isDataset(arg: any): arg is Dataset {
  return arg.description != null;
}

const emptyUrl = {
  test: '',
  mock: '',
  live: '',
};

const defaultStep: Step = {
  number: 1,
  name: '',
  httpMethod: '',
  urls: emptyUrl,
  piiLoggingLevel: PiiLoggingLevel.OFF,
  auditable: false,
};

const defaultDataset = {
  id: null,
  version: null,
  description: 'Loading ...',
  country: '',
  provider: '',
  status: 'live',
  pci_handler: false,
};

type EditStepParams = {
  id: string;
  version: string;
  stepNumber: string;
};

const EditStep = ({ identity }: { identity: Identity }) => {
  const { id, version, stepNumber } = useParams<EditStepParams>();
  const navigate = useNavigate();

  const [step, setStep] = useState<Step>(defaultStep);
  const [stepName, setStepName] = useState(defaultStep.name);
  const [detailsError, setDetailsError] = useState('');
  const [headerTemplateError, setHeaderTemplateError] = useState('');
  const [inputTemplateError, setInputTemplateError] = useState('');
  const [outputTemplatesError, setOutputTemplatesError] = useState('');
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [headerTemplate, setHeaderTemplate] = useState('');
  const [inputTemplate, setInputTemplate] = useState('');
  const [outputTemplates, setOutputTemplates] = useState<OutputTemplate[]>([]);
  const [loading, setLoading] = useState(true);
  const { upsertStep, getStep } = useSteps();
  const { getHeaderTemplate, updateHeaderTemplate, getInputTemplate, updateInputTemplate, getOutputTemplates } =
    useTemplates();
  const { getDataset } = useDatasets();
  const [dataset, setDataset] = useState<Dataset>(defaultDataset);

  const isAuthorisedToUpdatePiiLogging = useMemo(() => {
    const { authorised: authorisedToUpdatePiiLogging } = isAuthorised(identity, toUpdateLoggingPiiLevel);
    const { authorised: authorisedToUpdate } = isAuthorised(identity, toUpdate);
    return (
      (authorisedToUpdatePiiLogging &&
        (dataset.status.toLowerCase() === 'live' || dataset.status.toLowerCase() === 'testing')) ||
      (authorisedToUpdate && dataset.status.toLowerCase() !== 'retired')
    );
  }, [identity, dataset]);

  const isAuthorisedToUpdateOrAddTemplate = useMemo(() => {
    const { authorised } = isAuthorised(identity, toUpdate);
    return authorised && dataset.status.toLowerCase() === 'draft';
  }, [identity, dataset]);

  useEffect(() => {
    getDataset(Number(id), Number(version)).then(d => {
      if (isDataset(d)) {
        setDataset(d);
      } else {
        setDetailsError(`Error calling Get Dataset: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
    });
  }, [getDataset, id, version]);

  useEffect(() => {
    let loadingDetails = true;
    let loadingHeaderTemplate = true;
    let loadingInputTemplate = true;
    let loadingOutputTemplates = true;
    getStep(Number(id), Number(version), Number(stepNumber)).then(d => {
      if (isStep(d)) {
        setStep(d);
        setStepName(d.name);
      } else {
        setDetailsError(`Error calling Get Details: ${d.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`);
      }
      loadingDetails = false;
      if (!loadingHeaderTemplate && !loadingInputTemplate && !loadingOutputTemplates) setLoading(false);
    });
    getHeaderTemplate(Number(id), Number(version), Number(stepNumber)).then(t => {
      if (isTemplate(t)) {
        setHeaderTemplate(t.template);
      } else {
        setHeaderTemplateError(
          `Error calling Get Header Template: ${t.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
        );
      }
      loadingHeaderTemplate = false;
      if (!loadingDetails && !loadingInputTemplate && !loadingOutputTemplates) setLoading(false);
    });

    getInputTemplate(Number(id), Number(version), Number(stepNumber)).then(t => {
      if (isTemplate(t)) {
        setInputTemplate(t.template);
      } else {
        setInputTemplateError(
          `Error calling Get Input Template: ${t.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
        );
      }
      loadingInputTemplate = false;
      if (!loadingDetails && !loadingOutputTemplates && !loadingHeaderTemplate) setLoading(false);
    });

    getOutputTemplates(Number(id), Number(version), Number(stepNumber)).then(t => {
      if (t.length > 0) {
        if (isOutputTemplates(t)) {
          setOutputTemplates(t);
        } else {
          setOutputTemplatesError(
            `Error calling Get OutputTemplates: ${t.map((v, i) => `${v.problem}: ${v.action}`).join(', ')}`,
          );
        }
      }
      loadingOutputTemplates = false;
      if (!loadingDetails && !loadingInputTemplate && !loadingHeaderTemplate) setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const persistDetails = useCallback(
    async (datasetId: number, datasetVersion: number, stepData: Step) => {
      const errors = await upsertStep(datasetId, datasetVersion, step);
      if (!errors) {
        setStepName(stepData.name);
        toast({
          title: '',
          content: `${stepData.name} Details has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
      }
      return errors;
    },
    [step, upsertStep],
  );

  const persistHeaderTemplate = useCallback(
    async (template: string) => {
      const errors = await updateHeaderTemplate(Number(id), Number(version), Number(stepNumber), template);
      if (!errors) {
        toast({
          title: '',
          content: `Header template has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
      }
      return errors;
    },
    [id, version, stepNumber, updateHeaderTemplate],
  );

  const persistInputTemplate = useCallback(
    async (template: string) => {
      const errors = await updateInputTemplate(Number(id), Number(version), Number(stepNumber), template);
      if (!errors) {
        toast({
          title: '',
          content: `Input template has been saved`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
      }
      return errors;
    },
    [id, version, stepNumber, updateInputTemplate],
  );

  if (loading) {
    return (
      <>
        <h1 data-test-id="edit-dataset-loading-text">Loading Step</h1>
      </>
    );
  } else {
    return id && version && stepNumber ? (
      <>
        <Breadcrumb
          data-testid="edit-step-breadcrumb"
          crumbs={[
            {
              title: 'Datasets',
              link: 'datasets',
            },
            {
              title: `${dataset.description}`,
              link: `datasets/${id}/versions/${version}`,
            },
            {
              title: `Steps`,
              link: `datasets/${id}/versions/${version}?tab=Steps`,
            },
            {
              title: `${stepName}`,
              link: null,
            },
          ]}
        ></Breadcrumb>
        <div>
          <h1 data-testid="edit-step-header">{stepName}</h1>
        </div>
        <TabBar
          data-testid="step-tab-bar"
          onTabSelected={(i: number) => setTabIndex(i)}
          tabs={['Details', 'Header', 'Input', 'Output']}
          updateUrl={true}
        />
        <TabPanel data-testid="step-details-tab-panel" selectedIndex={tabIndex} index={0}>
          <StepForm
            step={step}
            setStep={setStep}
            persist={persistDetails}
            datasetId={id}
            datasetVersion={version}
            isAuthorisedToUpdatePiiLogging={isAuthorisedToUpdatePiiLogging}
            isAuthorised={isAuthorisedToUpdateOrAddTemplate}
            pci_handler={dataset.pci_handler}
          />
        </TabPanel>
        <TabPanel data-testid="step-Header-tab-panel" selectedIndex={tabIndex} index={1}>
          <TemplateForm
            datasetId={Number(id)}
            datasetVersion={Number(version)}
            templateName="Header"
            template={headerTemplate}
            setTemplate={setHeaderTemplate}
            persistTemplate={persistHeaderTemplate}
            isAuthorised={isAuthorisedToUpdateOrAddTemplate}
            stepNumber={stepNumber}
          />
        </TabPanel>
        <TabPanel data-testid="step-Input-tab-panel" selectedIndex={tabIndex} index={2}>
          <TemplateForm
            datasetId={Number(id)}
            datasetVersion={Number(version)}
            templateName="Input"
            template={inputTemplate}
            setTemplate={setInputTemplate}
            persistTemplate={persistInputTemplate}
            isAuthorised={isAuthorisedToUpdateOrAddTemplate}
            stepNumber={stepNumber}
          />
        </TabPanel>
        <TabPanel data-testid="step-Output-tab-panel" selectedIndex={tabIndex} index={3}>
          <OutputTemplatesTable
            isAuthorisedToUpdate={isAuthorisedToUpdateOrAddTemplate}
            datasetId={dataset.id}
            datasetVersion={dataset.version}
            stepNumber={step.number}
            templates={outputTemplates}
          ></OutputTemplatesTable>
          <br></br>
          {isAuthorisedToUpdateOrAddTemplate ? (
            <Button
              data-testid="add-output-template-button"
              onClick={() => {
                navigate(`/datasets/${dataset.id}/versions/${dataset.version}/steps/${step.number}/output/add`);
              }}
            >
              Add output template
            </Button>
          ) : null}
        </TabPanel>
        <ErrorText data-testid="retrieve-details-error-text">{detailsError}</ErrorText>
        <ErrorText data-testid="retrieve-header-template-error-text">{headerTemplateError}</ErrorText>
        <ErrorText data-testid="retrieve-input-template-error-text">{inputTemplateError}</ErrorText>
        <ErrorText data-testid="retrieve-output-templates-error-text">{outputTemplatesError}</ErrorText>
      </>
    ) : (
      <></>
    );
  }
};

export default EditStep;
