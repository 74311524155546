import { Error } from './Error';

export interface Dataset {
  id: number | null;
  description: string;
  version: number | null;
  country: string;
  provider: string;
  status: string;
  lastUpdated?: string;
  pci_handler: boolean;
  lastUpdatedBy?: string;
}

export interface DatasetWithFullCheckResults extends Dataset {
  checkResults: CheckResults;
}

export interface CheckResults {
  valid: boolean;
  issues: Error[];
}

export enum Status {
  Draft = 'DRAFT',
  SUBMITTED_TO_TESTING = 'SUBMITTED_TO_TESTING',
  Approval = 'APPROVAL',
  Testing = 'TESTING',
  Live = 'LIVE',
  Retired = 'RETIRED',
}

export function isDatasetWithCheckResults(object: any): object is DatasetWithFullCheckResults {
  if (object !== undefined) {
    return 'checkResults' in object;
  }
  return false;
}

export function isCheckResults(object: any): object is CheckResults {
  if (object !== undefined) {
    return 'valid' in object;
  }
  return false;
}
