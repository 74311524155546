import withConditionalRendering from '../../../components/withConditionalRendering';
import withApiCallHandling from '../../../components/withApiCallHandling';
import { isCheckResults, Status } from '../../../model/Dataset';
import DatasetValidation from './DatasetValidation';
import Dialog, { TestIds as DialogTestIds } from '../../../components/Dialog/Dialog';
import { useWorkflow } from '../../../hooks/useWorkflow';
import { useCallback, useMemo, useState } from 'react';
import {
  ErrorText,
  Button,
  InlineNotification,
  toast,
  ToastPosition,
  ToastType,
  InlineNotificationType,
  ButtonKind,
  ButtonSize,
  AlertBarType,
} from '@gbg/gbgcomponentlibrary_react';

export const TestIds = {
  VALIDATE_DATASET: 'validate-dataset',
  SUBMIT_TO_TESTING_BUTTON: 'submit-to-testing',
  APPROVED_BUTTON: 'approved-button',
  REJECT_BUTTON: 'reject-button',
  REVERT_DATASET_BUTTON: 'revert-dataset',
  PROMOTE_DATASET_BUTTON: 'promote-dataset',
  CONFIRM_PROMOTION_DIALOG: 'confirm-promote-dataset-dialog',
  CONFIRM_PROMOTION_BUTTON: 'confirm-promote-dataset-button',
  WORKFLOW_NOTIFICATION: 'workflow-notification',
  WORKFLOW_ACTION_ERROR_TEXT: 'workflow-action-error-text',
  ...DialogTestIds,
};

const DatasetValidationIfVisible = withConditionalRendering(DatasetValidation);
const ErrorTextIfVisible = withConditionalRendering(ErrorText);
const ButtonIfVisible = withConditionalRendering(Button);
const ButtonWithApiCallHandling = withApiCallHandling(Button);
const ButtonWithApiCallHandlingIfVisible = withConditionalRendering(ButtonWithApiCallHandling);
const NotificationIfVisible = withConditionalRendering(InlineNotification);

interface DatasetWorkflowProps {
  id: number;
  version: number;
  status: string;
  authorisedToUpdate: boolean;
  authorisedToPublish: boolean;
  authorisedToApproveToTesting: boolean;
  onSuccessfulWorkflowAction: () => void;
  lastUpdatedBy?: string;
}

const DatasetWorkflow = ({
  id,
  version,
  status,
  authorisedToUpdate,
  authorisedToPublish,
  authorisedToApproveToTesting,
  onSuccessfulWorkflowAction,
  lastUpdatedBy,
}: DatasetWorkflowProps) => {
  const [error, setError] = useState('');
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const { setWorkflowStatus, promote } = useWorkflow();

  const workflowStatusNotificationText = useMemo(() => {
    let text = '';
    switch (status.toUpperCase()) {
      case Status.Draft:
        text = 'The dataset is pending approval';
        break;
      case Status.SUBMITTED_TO_TESTING:
        text = authorisedToApproveToTesting
          ? `The dataset is submitted to testing by ${lastUpdatedBy} and is pending approval to testing or rejection to draft`
          : `The dataset is submitted to testing and is pending approval or rejection. Please ask another user to approve your changes to progress the dataset into testing.`;
        break;
      case Status.Testing:
        text = 'The dataset is approved for testing';
        break;
      case Status.Live:
        text = 'The dataset is live and cannot be modified';
        break;
    }
    return text;
  }, [status, lastUpdatedBy, authorisedToApproveToTesting]);

  const submitDataset = useCallback(
    async (status: Status) => {
      const result = await setWorkflowStatus(Number(id), Number(version), status);
      if (result === undefined || (isCheckResults(result) && result.valid)) {
        toast({
          title: '',
          content: `Dataset successfully promoted to Testing`,
          position: ToastPosition.Top,
          type: ToastType.Success,
          duration: 3000,
          dismissable: false,
        });
        onSuccessfulWorkflowAction();
      }
      return result;
    },
    [setWorkflowStatus, id, version, onSuccessfulWorkflowAction],
  );

  const callFunctionAndCloseDialog = useCallback(
    async <T,>(fn: () => T): Promise<T> => {
      const result = await fn();
      setConfirmationDialogVisible(false);
      return result;
    },
    [setConfirmationDialogVisible],
  );
  return (
    <>
      <NotificationIfVisible
        visible={[
          Status.Draft.toString(),
          Status.Testing.toString(),
          Status.Live.toString(),
          Status.SUBMITTED_TO_TESTING.toString(),
        ].includes(status.toUpperCase())}
        data-testid={TestIds.WORKFLOW_NOTIFICATION}
        type={InlineNotificationType.Info}
      >
        {workflowStatusNotificationText}
      </NotificationIfVisible>
      <br />
      <br />
      <DatasetValidationIfVisible
        visible={status.toUpperCase() === Status.Draft}
        id={id}
        version={version}
        isAuthorisedToUpdate={authorisedToUpdate}
        submitDataset={submitDataset}
      />

      <ButtonWithApiCallHandlingIfVisible
        visible={status.toUpperCase() === Status.SUBMITTED_TO_TESTING && authorisedToPublish}
        data-testid={TestIds.APPROVED_BUTTON}
        call={() => setWorkflowStatus(id, version, Status.Testing)}
        onSuccess={onSuccessfulWorkflowAction}
        onFailure={errorOnClick => setError(`Error approving dataset to testing: ${errorOnClick}`)}
        successMessage="Dataset successfully approved to testing"
        kind={ButtonKind.Secondary}
        size={ButtonSize.Small}
        className={'button-margin'}
        disabled={!authorisedToApproveToTesting}
      >
        Approve to Testing
      </ButtonWithApiCallHandlingIfVisible>

      <ButtonWithApiCallHandlingIfVisible
        visible={status.toUpperCase() === Status.SUBMITTED_TO_TESTING && authorisedToPublish}
        data-testid={TestIds.REJECT_BUTTON}
        call={() => setWorkflowStatus(id, version, Status.Draft)}
        onSuccess={onSuccessfulWorkflowAction}
        onFailure={errorOnClick => setError(`Error rejecting dataset from testing: ${errorOnClick}`)}
        successMessage="Dataset successfully rejected and reverted to draft"
        kind={ButtonKind.Destructive}
        size={ButtonSize.Small}
        className={'button-margin'}
      >
        Reject
      </ButtonWithApiCallHandlingIfVisible>

      <ButtonWithApiCallHandlingIfVisible
        visible={status.toUpperCase() === Status.Testing && authorisedToUpdate}
        data-testid={TestIds.REVERT_DATASET_BUTTON}
        call={() => setWorkflowStatus(id, version, Status.Draft)}
        onSuccess={onSuccessfulWorkflowAction}
        onFailure={error => setError(`Error reverting dataset's status to draft: ${error}`)}
        successMessage="Dataset successfully reverted to Draft"
        kind={ButtonKind.Secondary}
        size={ButtonSize.Small}
        className={'button-margin'}
      >
        Revert to Draft
      </ButtonWithApiCallHandlingIfVisible>
      <ButtonIfVisible
        visible={status.toUpperCase() === Status.Testing && authorisedToPublish}
        data-testid={TestIds.PROMOTE_DATASET_BUTTON}
        onClick={() => setConfirmationDialogVisible(true)}
        size={ButtonSize.Small}
        className={'button-margin'}
      >
        Promote to Live
      </ButtonIfVisible>
      <ErrorTextIfVisible visible={error !== ''} data-testid={TestIds.WORKFLOW_ACTION_ERROR_TEXT}>
        {error}
      </ErrorTextIfVisible>
      <Dialog
        data-testid={TestIds.CONFIRM_PROMOTION_DIALOG}
        isVisible={confirmationDialogVisible}
        type={AlertBarType.Warn}
        icon={'helpCircle'}
        title={'Promote Dataset to Live?'}
        subTitle={'Are you sure?'}
        text={'Once you have promoted this dataset to Live, you will not be able to make any further changes to it.'}
        onDismissed={() => setConfirmationDialogVisible(false)}
        dismissButtonText={'Cancel'}
      >
        <ButtonWithApiCallHandling
          data-testid={TestIds.CONFIRM_PROMOTION_BUTTON}
          kind={ButtonKind.Destructive}
          call={() => callFunctionAndCloseDialog(() => promote(id, version))}
          onSuccess={onSuccessfulWorkflowAction}
          onFailure={error =>
            callFunctionAndCloseDialog(() => setError(`Error promoting dataset's status to live: ${error}`))
          }
          successMessage="Dataset successfully promoted to Live"
          className={'button-margin'}
        >
          Promote to Live
        </ButtonWithApiCallHandling>
      </Dialog>
    </>
  );
};

export default DatasetWorkflow;
